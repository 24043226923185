<template>
  <v-container>
    <v-form>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" class="pt-10">
              <span><b>แบบบัญชีรายชื่อรายการที่ดินและสิ่งปลูกสร้าง</b></span>
              <span><b>เทศบาลเมืองอรัญญิก อำเภอเมืองพิษณุโลก จังหวัดพิษณุโลก</b></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="pt-2">
              <span>ชื่อ - สกุล</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.fullName }}
            </v-col>
            <v-col cols="2" class="pt-2">
              <span>เลขบัตรประจำตัวประชาชน</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.idCardNo }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>บ้านเลขที่</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.houseNo }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>หมู่</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.moo }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>ซอย</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.soy }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>ถนน</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.road }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>ตำบล/แขวง</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.subdistrict }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>อำเภอ/เขต</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.district }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>จังหวัด</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.province }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>รหัสไปรษณีย์</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.zipcode }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12"
          ><v-row>
            <v-col cols="12" class="pt-10">
              <span><b>รายการที่ดิน</b></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="pt-2">
              <span>ประเภทที่ดิน</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.landType }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>เลขที่เอกสารสิทธิ์</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.documentNo }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>เลขที่ดิน</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.landNo }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>หน้าสำรวจ</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.explore }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>สถานที่ตั้ง (หมู่/ตำบล)</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.address }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>จำนวนเนื้อที่ดิน </span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{
                `${data.areaRai} ไร่ ` +
                ` ${data.areaNgan} งาน ` +
                ` ${data.areaWah} วา`
              }}
            </v-col>
            <v-col cols="12" class="pt-2">
              <span>ลักษณะการทำประโยชน์</span>
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>ประกอบเกษตรกรรม</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.landProfitAgri }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>อยู่อาศัย</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.landProfitHome }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>อื่นๆ</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.landProfitOther }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>ว่างเปล่า/ไม่ทำประโยชน์</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.landProfitBlank }}
            </v-col>

            <v-col cols="2" class="pt-2">
              <span>ใช้ประโยชน์หลายประเภท</span>
            </v-col>
            <v-col cols="4" class="pt-2">
              {{ data.landProfitMany }}
            </v-col>
            
          </v-row>
          </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="pt-10">
          <span><b>รายการสิ่งปลูกสร้าง</b></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="pt-2">
          <span>บ้านเลขที่</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.buildingNo }}
        </v-col>
         <v-col cols="2" class="pt-2">
          <span>ประเภทสิ่งปลูกสร้าง(ตามบัญชีกรมธนารักษ์)</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.budildingName }}
        </v-col>
        <v-col cols="2" class="pt-2">
          <span>ลักษณะสิ่งปลูกสร้าง (ตึก/ไม้/ครึ่งตึกครึ่งไม้)</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.buildingType }}
        </v-col>
       <v-col cols="12" class="pt-2">
          <span>ลักษณะการทำประโยชน์</span>
        </v-col>

        <v-col cols="2" class="pt-2">
          <span>ประกอบเกษตรกรรม</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.buildingProfitAgri }}
        </v-col>

        <v-col cols="2" class="pt-2">
          <span>อยู่อาศัย</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.buildingProfitHome }}
        </v-col>

        <v-col cols="2" class="pt-2">
          <span>อื่นๆ</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.buildingProfitOther }}
        </v-col>

        <v-col cols="2" class="pt-2">
          <span>ว่างเปล่า/ไม่ทำประโยชน์</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.buildingProfitBlank }}
        </v-col>

        <v-col cols="2" class="pt-2">
          <span>อายุโรงเรือนหรือสิ่งปลูกสร้าง</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.buildingAge }}(ปี)
        </v-col>

        <v-col cols="2" class="pt-2">
          <span>หมายเหตุ</span>
        </v-col>
        <v-col cols="4" class="pt-2">
          {{ data.buildingNote }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5"></v-col>
        <v-col cols="2" class="py-4">
          <v-btn color="#744D9c" style="color:white;" @click="backpds3()">กลับ</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      list: [],
      count: 0,
      checkuser: [],
      data: [],
    };
  },
  created() {
    this.checkuser = JSON.parse(Decode.decode((localStorage.getItem("user"))));
    this.data = JSON.parse(Decode.decode(localStorage.getItem("data")));
    console.log("data", this.data); // this.pds1data คือค่าที่ส่งมาจาก แว่นขยาย
    // this.getviewallpds1();
   
  },
  methods: {
   backpds3() {
   this.$router.push("/pds3");
   },
    
  },
  };
</script>
<style scoped>
.f-right {
  float: right;
}
</style>
